import Organization from './organization';

export class OrganizationUsageStats {
  constructor(
    public totalTeachers: number | undefined,
    public totalAssignments: number | undefined,
    public totalStudents: number | undefined,
    public totalHelpRequestsResolved: number | undefined,
    public activeTeachers: Record<string, unknown>[],
    public teachersActiveThisYear: number | undefined,
    public studentsActiveThisYear: number | undefined,
    public assignmentsCreatedThisYear: number | undefined,
    public assignmentsWorkedOnThisYear: number | undefined,
    public assignmentQuestionsWorkedOnThisYear: number | undefined,
    public rostersCreatedThisYear: number | undefined,
    public helpRequestsResolvedThisYear: number | undefined,
  ) {}

  get pagesWorkedOnThisYear(): number | undefined {
    if (!this.assignmentQuestionsWorkedOnThisYear) return;

    return this.assignmentQuestionsWorkedOnThisYear;
  }

  get approximateSavings(): number | undefined {
    if (!this.pagesWorkedOnThisYear) return;

    const costPerPage = 0.09;
    return this.pagesWorkedOnThisYear * costPerPage;
  }

  get approximateSavingsDisplay(): string | undefined {
    if (!this.approximateSavings) return;

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(this.approximateSavings);
  }
}

// eslint-disable-next-line import/no-default-export
export default OrganizationUsageStats;

export class OrganizationsUsageStats {
  constructor(
    public organization: Organization,
    public stats: OrganizationUsageStats,
  ) {}
}
