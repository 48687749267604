'use strict';

import mixpanel from 'mixpanel-browser';
import moment from 'moment';

/**
 * Contains methods to log events to mixpanel
 */
export default class MixpanelService {

  constructor(environment, $log, $q) {
    'ngInject';

    this.$log = $log;
    this.$q = $q;
    this.environment = environment;

    this._enabled = false;

    /** @type {UserTokenInfo} */
    this._userData = null;

    /** @type {Deferred} */

    this._defaultProperties = {
      'platform': 'web'
    };

    this._testMixpanel = null;
  }

  init(userData, abTestSegments){
    if (this.environment.mixpanelId) {
      mixpanel.init(this.environment.mixpanelId,
        {
          persistence: 'localStorage',
          record_sessions_percent: 2  //records 2% of all sessions
        }
      );
      this._enabled = true;
    }
    this.identify(userData, abTestSegments);
  }

  /**
   * @param [userData] {UserTokenInfo}
   * @param [abTestSegments] {Map.<String, String>}
   */
  identify(userData, abTestSegments = new Map()) {
    this._userData = userData;

    let contractType = null;
    if (this._userData) {
      if (this._userData.isProClassroom) {
        contractType = 'pro';
      }
      else if (this._userData.isProSchool) {
        contractType = 'proSchool';
      }
      else if (this._userData.isProDistrict) {
        contractType = 'proDistrict';
      }
      else {
        contractType = 'free';
      }
    }

    if (contractType) {
      this._defaultProperties['contractType'] = contractType;
    } else {
      delete this._defaultProperties['contractType'];
    }

    if (this._userData && this._userData.isTeacher) {
      const properties = {};
      abTestSegments.forEach((v, k) => {
        properties[`abtest.${k}`] = v;
      });
      properties['userType'] = 'teacher';

      if (this._enabled) {
        try {
          this._mixpanel.identify(this._userData.id);
          this._mixpanel.register(properties);

          if (contractType) {
            this._mixpanel.people.set('contractType', contractType);
          }
        }
        catch (err) {
          this.$log.error('mixpanel error', err);
        }
      }

      this.$log.debug('mixpanel identify:', this._userData.id, properties);
    }
    else {
      if (this._enabled) {
        try {
          this._mixpanel.reset();
          this._mixpanel.register({});
        }
        catch (err) {
          this.$log.error('mixpanel error', err);
        }
      }

      this.$log.debug('mixpanel identify: cleared');
    }
  }

  getDistinctId() {
    return this._mixpanel.get_distinct_id();
  }

  /**
   * @param eventName {string}
   * @param [properties] {Object}
   * @param [includeCountAndLast] {boolean}
   */
  send(eventName, properties, includeCountAndLast) {
    if (!properties) {
      properties = {};
    }

    const props = {...properties, ...this._defaultProperties};

    if (this._enabled) {
      try {
        this._mixpanel.track(eventName, props);

        if (includeCountAndLast) {
          this._mixpanel.people.increment(`${eventName}_Count`);
          this._mixpanel.people.set(`${eventName}_Last`, moment().toISOString());
        }

        this.$log.debug(`mixpanel event: ${eventName} includeCountAndLast=${includeCountAndLast}`, props);
      } catch (err) {
        this.$log.error('mixpanel error', err);
      }
    } else {
      this.$log.debug(`[disabled] mixpanel event: ${eventName} includeCountAndLast=${includeCountAndLast}`, props);
    }
  }

  get _mixpanel() {
    return this._testMixpanel || mixpanel;
  }

  setProperty(property, value){
    this._mixpanel && this._mixpanel.people && this._mixpanel.people.set(property, value);
  }
}

export class AccountCreationStep {

  static get STARTED() {
    return 'started';
  }

  static get NAME() {
    return 'name';
  }

  static get LOCATION() {
    return 'location';
  }

  static get REFERRAL() {
    return 'referral';
  }

  static get COMPLETE() {
    return 'complete';
  }

  static get SCHOOL_NAME() {
    return 'schoolName';
  }
}

export class Locations {
  static get ASSIGNMENT_ASSIGN() {
    return 'assignmentAssign';
  }

  static get ASSIGNMENT_ASSIGN_NAV() {
    return 'assignmentAssignNav';
  }

  static get ASSIGNMENT_EDIT() {
    return 'assignmentEdit';
  }

  static get ASSIGNMENT_EDIT_NAV() {
    return 'assignmentEditNav';
  }

  static get ASSIGNMENT_EDIT_SINGLE_QUESTION() {
    return 'assignmentEditSingleQuestion';
  }

  static get ASSIGNMENT_EDIT_ROSTER() {
    return 'assignmentEdit-roster';
  }

  static get ASSIGNMENT_EDIT_TOP() {
    return 'assignmentEdit-top';
  }

  static get ASSIGNMENT_LIST() {
    return 'assignmentList';
  }

  static get ASSIGNMENT_LIBRARY() {
    return 'assignmentLibrary';
  }

  static get ASSIGNMENT_WATCH() {
    return 'assignmentWatch';
  }

  static get ASSIGNMENT_WORK() {
    return 'assignmentWork';
  }

  static get ASSIGNMENT_WORK_QUESTION() {
    return 'assignmentWorkQuestion';
  }

  static get ASSIGNMENT_VIEW_WORK_NAV() {
    return 'assignmentViewWorkNav';
  }

  static get ASSIGNMENT_VIEW_WORK() {
    return 'assignmentViewWork';
  }

  static get EMAIL() {
    return 'email';
  }

  static get FEATURE_CHART() {
    return 'featureChart';
  }

  static get HEADER() {
    return 'header';
  }

  static get LOGIN() {
    return 'login';
  }

  static get METRICS() {
    return 'metrics';
  }

  static get NAV() {
    return 'nav';
  }

  static get ORDER_DETAILS() {
    return 'orderDetails';
  }

  static get ORDER_PAGE() {
    return 'orderPage';
  }

  static get PROFILE_PLAN() {
    return 'profilePlan';
  }

  static get PUBLIC_ASSIGNMENT() {
    return 'publicAssignment';
  }

  static get PUBLIC_ASSIGNMENT_QUESTION() {
    return 'publicAssignmentQuestion';
  }

  static get ROSTER_LIST() {
    return 'rosterList';
  }

  static get ROSTER_DETAILS_LIST() {
    return 'rosterDetailsList';
  }

  static get STUDENT_ASSIGNMENT_LIST() {
    return 'studentAssignmentList';
  }

  static get UNKNOWN() {
    return 'unknown';
  }
}

export class Actions {
  static get CLICKED(){
    return 'clicked';
  }
  static get OPENED(){
    return 'opened';
  }
  static get CLOSED(){
    return 'closed';
  }
}

export class TrackingIds {
  static get QUOTE_PAGE_VIEW() {
    return 'quote_page_view';
  }

  static get EMAIL_SENT() {
    return 'admin_email_sent';
  }

  static get CONVERSION_COMMITMENT() {
    return 'conversion_commitment';
  }

  static get CONVERSION_VIEW() {
    return 'conversion_view';
  }

}

export class ShareWorkflowSteps {
  static get STARTED() {
    return 'started';
  }

  static get WITH_COLLEAGUES() {
    return 'withColleagues';
  }

  static get WITH_STUDENTS() {
    return 'withStudents';
  }

  static get VIA_EMAIL() {
    return 'viaEmail';
  }

  static get VIA_LINK() {
    return 'viaLink';
  }

  static get VIA_GOOGLE_PLUS() {
    return 'viaGooglePlus';
  }

  static get VIA_FACEBOOK() {
    return 'viaFacebook';
  }

  static get VIA_TWITTER() {
    return 'viaTwitter';
  }

  static get VIA_PINTEREST() {
    return 'viaPinterest';
  }

  static get PUBLIC_PAGE() {
    return 'publicPage';
  }
}

export class PaywallSources {

  static get GRADEBOOK_INTEGRATION() {
    return 'gradebook_integration';
  }

  static get SIDEBAR_BUTTON() {
    return 'sidebar_button';
  }

  static get NEW_FOLDER() {
    return 'new_folder';
  }

  static get STUDENT_EXPORT() {
    return 'student_export';
  }
  static get GRADE_EXPORT() {
    return 'grade_export';
  }

  static get ANSWER_EXPORT() {
    return 'answer_export';
  }

  static get SCIENTIFIC_FITB() {
    return 'scientific_fitb';
  }

  static get ROSTER_GRADE_EXPORT() {
    return 'roster_grade_export';
  }

  static get IMPORT_GOOGLE_ROSTERS() {
    return 'import_google_rosters';
  }

  static get STUDENT_ACCOUNTS_ADD_STUDENTS_DIALOG() {
    return 'student_accounts_add_students_dialog';
  }

  static get STUDENT_ACCOUNTS_HELP_DIALOG() {
    return 'student_accounts_help_dialog';
  }

  static get ASSIGNMENTS_LIMIT_FIRST_TIME_DIALOG() {
    return 'assignments_limit_first_time_dialog';
  }

  static get ASSIGNMENTS_LIMIT_PAYWALL_DIALOG() {
    return 'assignments_limit_paywall_dialog';
  }

  static get STUDENT_OVERVIEW_PAYWALL() {
    return 'student_overview_paywall';
  }
}

export class SubscriptionFunnel {
  static get PAYWALL() {
    return 'paywall';
  }

  static get START_TRIAL() {
    return 'startTrial';
  }

  static get LEARN_MORE() {
    return 'learnMore';
  }

  static get ASSIGNMENT_LIMIT_HEADER() {
    return 'assignmentLimitHeader';
  }

  static get ORDER_PRO() {
    return 'orderPro';
  }

  static get ENTER_PAYMENT() {
    return 'enterPayment';
  }

  static get CANCEL() {
    return 'cancel';
  }

  static get COMPLETE() {
    return 'complete';
  }

  static get SCHOOL_CLOSURE() {
    return 'school_closure';
  }

}

export class ProfileViewFunnel {

  static get VIEWED() {
    return 'viewed';
  }

  static get CLICKED_ASSIGNMENT() {
    return 'clickedAssignment';
  }

}

export class GoogleRosterImportFunnel {

  static get VIEWED() {
    return 'viewed';
  }

  static get SELECTED() {
    return 'selected';
  }

  static get RETRIEVED() {
    return 'retrieved';
  }

}

export class StickerSources {

  static get FEEDBACK() {
    return 'feedback';
  }

  static get CONTENT() {
    return 'content';
  }

  static get SESSION_QUESTION() {
    return 'session_question';
  }

}

export class SessionSettingTypes {

  static get ShowWork() {
    return 'showWork';
  }

  static get HideUnstartedThumbnails() {
    return 'hideUnstartedThumbnails';
  }

  static get HideOfflineStudents() {
    return 'hideOfflineStudents';
  }

  static get HideUnstartedAssignmentWorks() {
    return 'hideUnstartedAssignmentWorks';
  }

  static get HideStudentNames() {
    return 'hideStudentNames';
  }

  static get ShowStudentHelpers() {
    return 'showStudentHelpers';
  }

  static get ShowStudentScores() {
    return 'showStudentScores';
  }

  static get LockStudentWork() {
    return 'lockStudentWork';
  }

  static get HideStudentWork() {
    return 'hideStudentWork';
  }

  static get AllowPdf() {
    return 'allowPdf';
  }

}

export class TeacherHelpCenterViews {

  static get Main() {
    return 'main';
  }

  static get Category() {
    return 'category';
  }

  static get Section() {
    return 'section';
  }

  static get Article() {
    return 'article';
  }

  static get Coaches() {
    return 'coaches';
  }

}

export class TeacherType {
  static get PRIMARY(){
    return 'primary';
  }

  static get CO_TEACHER(){
    return 'co-teacher';
  }
}
